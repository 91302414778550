<template>
	<div>
		<OfferTalentPool :loading-parent="loadingOffer" v-if="!offer.category.justExternal" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.title ? `${this.$t('offers.offer')} - ${this.title}` : this.$t('offers.offer')
		}
	},
	data() {
		return {
			title: ''
		}
	},
	components: {
		OfferTalentPool: () => import('@/components/offers/OfferTalentPool.vue')
	},
	computed: {
		...mapGetters({
			offer: 'offers/offer',
			loadingOffer: 'offers/loading'
		})
	}
}
</script>
